import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BlockUI from 'vue-blockui'
import {axios} from 'axios';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(BlockUI)


Vue.use(VueSimpleAlert);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
