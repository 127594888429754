<template>
    <div>
        <BlockUI :message="msg" v-if="loading">
            <img :src="url" alt="Loading" width="60" class="align-center">
        </BlockUI>

        <div class="container" v-if="showForm">
            <div v-if="!read_form" >
                <header class="header">
                    <div class="container">
                        <div class="header-row">
                            <div class="header-menu-dropdown">
                                <span>{{ $store.state.user.name }}</span>
                                <div class="header-avatar">
                                    <img src="@/assets/user.png" alt="">
                                </div>
                                <ul class="header-menu-items">
                                    <li>
                                        <button type="button" @click="Logout">Logout</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
                <section class="py-5">
                    <div class="form-container">
                        <div class="form-container-header">
                            <img width="150"
                                src="https://master-3.corporate.ferring.tech/wp-content/uploads/sites/16/2021/01/Ferring-logo.png"
                                alt="">
                            <h5 class="text-dark"><strong>Payment Information of
                                    <router-link :to="`/form?program_id=${program_details.guid}`" target="_blank">#{{
                                        program_details.id }}</router-link>
                                </strong></h5>
                        </div>
                        <form id="main-form" name="main-form" enctype="multipart/form-data" @submit.prevent="submitHandler">
                            <div class="group" id="requesting-organization-group">
                                <div class="col-left">
                                    <label for="requesting-organization" class="label-required">Amount</label>
                                </div>
                                <div class="col-right">
                                    <input type="number" id="amount" 
                                    name="amount"
                                    required
                                    v-model="form.amount" 
                                    @input="filterNonPositiveInPaymentPage"
                                    >
                                </div>
                            </div>
                            <!-- Date of program -->
                            <div class="group" id="date-of-program-group">
                                <div class="col-left">
                                    <label for="date-of-program" class="label-required">Date</label>
                                </div>
                                <div class="col-right">
                                    <!-- <input type="date" id="payment-date" name="payment-date" v-model="form.payment_date" 
                                        required> -->
                                    <date-picker v-model="form.payment_date" format="MM-DD-YYYY" :editable=true
                                        valueType='YYYY-MM-DD'
                                       required></date-picker>

                                </div>
                            </div>

                            <!-- Payment Type Field -->
                            <div class="group" id="credit-card-accepted-group">
                                <div class="col-left">
                                    <label for="credit-card-accepted" class="label-required">Type</label>
                                </div>
                                <div class="col-right">
                                    <div class="radio">
                                        <input type="radio" id="credit-card-accepted-yes" name="credit-card-accepted"
                                            value="ACH" required v-model="form.type"><label
                                            for="credit-card-accepted-yes">ACH</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" id="credit-card-accepted-no" name="credit-card-accepted"
                                            value="Purchase Card" required v-model="form.type"><label
                                            for="credit-card-accepted-no">Purchase Card</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" id="credit-card-accepted-no1" name="credit-card-accepted"
                                            value="Check" required v-model="form.type"><label
                                            for="credit-card-accepted-no1">Check</label>
                                    </div>
                                </div>
                            </div>


                            <!-- remarks -->

                            <div class="group" id="attachments-group">
                                <div class="col-left">
                                    <label for="attachments" class="label-required">Remarks</label>
                                </div>
                                <div class="col-right">
                                    <div class="file">
                                        <textarea name="" id="" cols="30" rows="4" v-model="form.remarks" required></textarea>

                                    </div>


                                </div>
                            </div>
                            <!-- Remarks -->
                            <div class="group" id="attachments-group">
                                <div class="col-left">
                                    <label for="attachments" class="label-required">Attachments</label>
                                </div>
                                <div class="col-right">
                                    <div class="file">
                                        <input type="file" id="attachments" name="attachments" multiple ref="file"
                                            @change="uploadFile" required>
                                        <label for="attachments">choose a file</label>
                                    </div>

                                    <div class="file-name">
                                        <ul>
                                            <li v-for="(attachment, i) in form.Attachment" :key="i">{{ attachment.name }}
                                                <span @click="removeFile(i)">
                                                    <i class="fa fa-times" style="color:red"></i>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <!-- Form Notes -->
                            <!-- <div id="errors" class="invalid"></div> -->
                            <div class="form-footer">
                                <div class="form-alert" id="form-alert"></div>
                                <button type="submit" id="submit">Submit</button>
                               
                            </div>
                        </form>
                    </div>
                </section>
            </div>
            
            <div v-if="read_form" >
                <div class="form-container-header">
                    <img width="150"
                        src="https://master-3.corporate.ferring.tech/wp-content/uploads/sites/16/2021/01/Ferring-logo.png"
                        alt="">

                </div>

                <section class="py-5" >
                    <div class="form-container">
                        <h5 class="text-dark text-center">
                            <strong>Payment Information of
                                <router-link :to="`/form?program_id=${program_details.guid}`" target="_blank">#{{
                                    program_details.id }}</router-link>
                            </strong>
                        </h5>
                        <table class="table table-sm">
                            <tr>
                                <th>Amount</th>
                                <td>Rs. {{ read_form.ActualPaymentAmount }}</td>
                            </tr>
                            <tr>
                                <th>Payment Date</th>
                                <td> {{ read_form.PaymentDate }}</td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>{{ read_form.PaymentType_ID }}</td>
                            </tr>
                            <tr>
                                <th>Remarks</th>
                                <td>{{ read_form.Remarks }}</td>
                            </tr>
                            <tr>
                                <th>Created By</th>
                                <td>{{ read_form.CreatedBy }}</td>
                            </tr>
                            <tr>
                                <th>Attachments</th>
                                <td>
                                    <ul>
                                        <li v-for="(attachment, i) in JSON.parse(read_form.Attachments)" :key="i">
                                            <a :href="attachment.url" target="_blank">{{ attachment.originalname }}</a>

                                        </li>
                                    </ul>

                                </td>
                            </tr>
                            <tr>
                                <th>Created Date</th>
                                <td>{{ read_form.Created_ts }}</td>
                            </tr>
                        </table>
                    </div>

                </section>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import loadingImage from '@/assets/load.gif';
import mixin from '@/mixins/common.utils'
export default {
    components: { DatePicker },
    mixins: [mixin],
    data() {
        return {
            msg: 'Loading ... ',
            url: loadingImage,
            loading: true,
            program_details: {
                id: '',
                guid: ''
            },

            showForm: false,
            showError: false,
            form: {
                Attachment: [],
                amount: '',
                payment_date: '',
                type: 'ACH',
                remarks: '',
            },
            read_form: false

        }
    },
    mounted() {
        this.getDetails();
    },
    methods: {
        Logout() {
            this.$store.dispatch('LogOut');
        },
        submitHandler() {
            this.$confirm("Are you sure want to submit the form?")
                .then(() => {
                    let formData = new FormData();
                    formData.append("Program_Id", this.$route.query.program_id);
                    formData.append("ActualPaymentAmount", this.form.amount);
                    formData.append("Remarks", this.form.remarks);
                    formData.append("PaymentDate", this.form.payment_date);
                    formData.append("PaymentType", this.form.type);
                    for (const i of Object.keys(this.form.Attachment)) {
                        formData.append('Attachment', this.form.Attachment[i])
                    }
                    this.loading = true;
                    axios.post('/payment-information', formData)
                        .then(resp => {
                            console.log(resp.data);
                            this.loading = false;
                            if (resp.data.success) {
                                this.$fire({
                                    title: "Successfully Submitted !",
                                    type: "success",
                                }).then(r => {
                                    this.getDetails();
                                    // this.$router.push('/form?program_id=' + this.$route.query.program_id);
                                });
                            }else{
                                this.$fire({
                                    title: "Error: something went wrong",
                                    type: "error",
                                }).then(r => {
                                    window.location.reload();
                                });
                            }
                        })

                })

        },
        uploadFile() {
            const files = this.$refs.file.files;
            Array.from(files).forEach(element => {
                const file = element;
                if (file.size > 1024 * 1024 * 250) {
                    alert('File too big (>250MB)');
                    return;
                } else {
                    this.form.Attachment.push(element);
                }
            });
        },
        removeFile(i) {
            const data = Array.from(this.form.Attachment)
            document.getElementById('attachments').value = "";
            console.log(data);
            data.splice(i, 1)
            console.log(data)
            this.form.Attachment = data;
        },
        getDetails() {
            let program_id = this.$route.query.program_id;
            if (!program_id) {
                this.$router.push('/login');
            }
            axios.get(`/payment-information-check/${program_id}`)
                .then(res => {
                    if (res.data.success) {
                        this.loading = false;
                        this.showForm = true;
                        this.program_details.id = res.data.data.RequestID;
                        this.program_details.guid = this.$route.query.program_id;
                    } else {
                        console.log(res.data.data);
                        this.loading = false;
                        this.showForm= true;
                        this.read_form = res.data.data;
                        this.program_details.id = res.data.data.request_id;
                        this.program_details.guid = this.$route.query.program_id;
                        this.showError = res.data.message;
                    }
                    console.log(res.data);
                }).catch(err => {
                    if (err.response.status == 403) {
                        this.loading = false;
                        this.$fire({
                            title: "Unauthorized Access",
                            type: "error",
                        }).then(r => {
                            this.$store.dispatch('LogOut');
                        });
                    }
                    
                })
        }
    },

}
</script>
<style>
.mx-datepicker {
    width: 100% !important;
}</style>