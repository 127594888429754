<template>
    <div>
        <BlockUI :message="msg" >
            <img :src="url" alt="Loading" width="60">
        </BlockUI>
    </div>
</template>
<script>
    import loadingImage from '@/assets/load.gif'
    import { mapActions } from "vuex"; 
    export default {
        data(){ 
            return {
                msg: 'Loading ... ',
                url : loadingImage  
            }
        },
        mounted(){
            this.initialize();
        },
        methods:{
            ...mapActions(["LogIn"]),
           async  initialize(){
               let local_state_code = localStorage.getItem('state_code'); 
               let state_code =  this.$route.query.state;
                if(local_state_code == state_code) {
                let code =  this.$route.query.code;
                await this.LogIn(code);
                localStorage.setItem("firstTime", "true");
                let redirect_url = localStorage.getItem('redirect_url');
                if(redirect_url) {
                    localStorage.removeItem('redirect_url')
                    location.href = redirect_url;
                }else{
                    this.$router.push('/');
                }
               

                }else{
                    this.$router.push('/');
                }
            }
        }
    }
</script>
styl