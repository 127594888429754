import axios from 'axios';
import store from './store'

// axios.defaults.withCredentials = true

//axios.defaults.baseURL = 'http://localhost:8080/api/v1';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    var access_token = store.state.access_token;
    console.log(access_token);
    console.log(store.state.refresh_token);
    if (access_token) {
      config.headers['Authorization'] = 'Bearer ' + access_token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response !== null) 
    {
      if (error.response.status === 401 && !originalRequest._retry)
      {
       
        store.dispatch('LogOut');
        // originalRequest._retry = true;
        // store.dispatch('RefreshToken')
        // .then(res => {
        //   axios.defaults.headers.common["Authorization"] = res.access_token;
        //   return axios(originalRequest);
        // })
        // .catch(err => {
        //   console.log("err");
        //   console.log(err);
        //   return Promise.reject(error);
        // })
      
      }else if(error.response.status == 500){
        console.log("SERVER ERROR");
        alert("Server Error");
        store.dispatch('LogOut')
        
      }
    }
    return Promise.reject(error);
  }
);


export default axios;