var mixin = {
    methods: {
        filterNonPositive(){
            if (this.form.dollarAmountRequested < 0 || isNaN(this.form.dollarAmountRequested)) {
                this.form.dollarAmountRequested = '';
            }
        },
        filterNonPositiveInPaymentPage(){
            if (this.form.amount < 0 || isNaN(this.form.amount)) {
                this.form.amount = '';
            }
        }
    }
  }
  
  export default mixin;