import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios';
import router from '../router'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:'',
    access_token:'',
    refresh_token:''
  },
  getters: {
    isAuthenticated: state => !!state.user,  
    AccessTokenAvailable: state => !!state.access_token,  
    getRefreshToken: state =>  state.refresh_token,    
    StatePosts: state => state.posts,
    StateUser: state => state.user,
  },
  mutations: {
    setUser(state, username){
      state.user = username
    },
    setAccessToken(state, access_token){
      state.access_token = access_token
    },
    setRefreshToken(state, refresh_token){
      state.refresh_token = refresh_token
    },
    LogOut(state){
      state.user = null;
      state.access_token = null;
      state.refresh_token = null;
    }
  },
  actions: {
    async LogIn({commit}, code) {
      try {
        let resp = await axios.post('auth/login', {
          code: code
        })
      
        if(resp.data.success){
           commit('setUser',resp.data.user_info);
           commit('setAccessToken',resp.data.access_token);
           commit('setRefreshToken',resp.data.refresh_token);
        }else{
          alert("Something went wrong");
          router.push('/login')
        }
      }
      catch(err) {
        console.log(err);
        if(err.response.status == 401){
          localStorage.clear();
          alert("Unauthorized Access");
          router.push('/login')
        }else{
          localStorage.clear();
          alert("Something Went Wrong");
          router.push('/login')
        }
       
      }
      
    },
    async RefreshToken({commit,state}) {
     
        try  {
          let resp = await axios.post('auth/generate/access-token', {
            refresh_token: state.refresh_token
          })
  
          await commit('setAccessToken',resp.data.access_token);
          await commit('setRefreshToken',resp.data.refresh_token);
          return resp.data;
        }
        catch(err){
          console.log(err);
        }
        
       
    },
    async LogOut({commit,state}){
      let redirectUrl = process.env.VUE_APP_LOGOUT_URL;
      let clientID = process.env.VUE_APP_CLIENT_ID;
      let url = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${redirectUrl}&id_token_hint=${state.access_token}&client_id=${clientID}`

      localStorage.clear();
      commit('LogOut')
      window.location.href= url;
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
