import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import CreateExhibitFundAppRequest from '../views/CreateExhibitFundAppRequest.vue'
import paymentPage from '../views/PaymentPage.vue'
import loginWebhook from '../views/LoginWebhook'
import AcceptRequestView from '../views/EmailResponseView'
import formView from '../views/formview'
import formError from '../views/formError'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'form',
    component: CreateExhibitFundAppRequest,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/payment-information',
    name: 'paymentDetails',
    component: paymentPage,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/auth/login/webhook',
    name: 'loginWebhook',
    component: loginWebhook,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/error',
    name: 'form-error',
    component: formError,
    meta: {
      isAuthenticated: false
    }
  },
  {
    path: '/email/form-request',
    name: 'AcceptRequest',
    component: AcceptRequestView,
    meta: {
      isAuthenticated: true
    }
  },
  {
    path: '/form',
    name: 'formView',
    component: formView,
    meta: {
      isAuthenticated: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  if(to.meta.isAuthenticated == true){
    console.log(to);
    console.log(from)
    let access_token = store.getters.AccessTokenAvailable;
    let redirect_url = encodeURIComponent(window.location.href)
    console.log(redirect_url);
     if(access_token)  { 
      next()
     } else {
       next('/login?redirect_url='+redirect_url);
     }  
  }else{
    console.log(to);
    if(to.name == 'login'){
      let access_token = store.getters.AccessTokenAvailable;
      if(access_token)  { 
          next('/');
      }
    }
    console.log(from)
    next()
  }
})
export default router
